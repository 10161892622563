import Link from 'core-web/components/Link';
import { inServer } from 'core-web/constants';
import LoadingContainer from 'core-web/containers/LoadingContainer';
import SearchContainer from 'core-web/containers/SearchContainer';
import { Search } from 'core-web/libs/Algolia-v2';
import { buildQueryString } from 'core-web/libs/grebbcommerce-api/util';
import scroll from 'core-web/libs/scroll';
import { getState, injectModels } from 'core-web/state';
import getQueryParams from 'core-web/util/getQueryParams';
import Cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import LoginButton from 'theme/components/Header/Navbar/LoginButton';
import Menu from 'theme/components/Header/Navbar/MobileNavbar/Menu';
import SearchContent from 'theme/components/Header/Navbar/MobileNavbar/Search/SearchContent';
import SearchInput from 'theme/components/Header/Navbar/MobileNavbar/Search/SearchInput';
import BasketIcon from 'theme/components/icons/BasketIcon';
import HamburgerIcon from 'theme/components/icons/HamburgerIcon';
import LogoIcon from 'theme/components/icons/Logo';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Header = styled('header')`
    position: relative;
    z-index: 151;
    min-height: 126px;

    @media (min-width: 1280px) {
        min-height: 64px;
    }
`;

const Wrapper = styled('div')`
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    height: 64px;
    background-color: ${colors.white};
`;

const Inner = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    position: relative;

    padding: 0 16px;
`;

const SearchWrapper = styled('div')`
    position: fixed;
    width: 100vw;
    left: 0px;
    bottom: 0px;

    z-index: 99999;
    overflow: auto;
    background: white;

    @media (min-width: 1280px) {
        left: -12px;
    }
`;

const Flex = styled('div')({ display: 'flex' });
const Span = styled('span')();
const Button = styled('button')({ display: 'flex', alignItems: 'center' });

const LogoLink = styled(Link)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
`;

const SearchInputWrapper = styled('div')``;

class MobileNavbar extends Component {
    static getDerivedStateFromProps(props, state) {
        if (props.menuLinks.length && state.algoliaHits.length) {
            const newMenuItems = [...props.menuLinks];
            MobileNavbar.addThumbs(newMenuItems, state.algoliaHits);
            return {
                menuItems: newMenuItems,
            };
        }
        return null;
    }

    static addThumbs = (items, algoliaHits) => {
        items.forEach((item) => {
            if (item.length) {
                const hit = algoliaHits.find((hit) => hit.external_id === parseInt(item.menu_link.link_id, 10));
                item.thumbnail = hit.settings?.thumbnail?.url;
                if (!item.thumbnail) {
                    item.thumbnail = hit.hero_content?.data?.image?.url;
                }
                if (item.sub_links) {
                    MobileNavbar.addThumbs(item.sub_links, algoliaHits);
                }
            }
        });
    };

    static propTypes = {
        application: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        menuLinks: PropTypes.array,
        overlay: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
        search: PropTypes.object.isRequired,
    };

    static defaultProps = {
        menuLinks: [],
    };

    constructor(props) {
        super(props);
        this.siteSearch = debounce(this.siteSearch, 250);
        this.state = {
            algoliaHits: [],
            isMenuOpen: false,
            isSearchOpen: false,
            menuItems: props.menuLinks,
            transform: false,
        };
    }

    componentDidMount() {
        const { menuLinks } = this.props;
        window.addEventListener('scroll', this.handleScroll);
        this.fetchMenuItems(menuLinks);
    }

    // TODO: Maybe replace this with getDerivedStateFromProps

    // componentDidUpdate(prevProps) {
    //     if (prevProps.search.query !== this.props.search.query) {
    //         this.setState({ query: this.props.search.query });
    //     }
    // }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > 40) {
            this.setState({ transform: true });
        } else {
            this.setState({ transform: false });
        }
    };

    // Handle the query and inputs
    siteSearch = (query) => {
        const { search, history, location } = this.props;

        const params = getQueryParams(location);
        let locationClone = cloneDeep(location);

        if (Object.prototype.hasOwnProperty.call(params, 'page') && !inServer) {
            const updatedQueryParams = `?${buildQueryString(params)}`;
            locationClone = { ...locationClone, search: updatedQueryParams };
        }
        search.search(query, history, locationClone);

        if (query && query.length < 1) {
            search.clear(history, location);
        }
    };

    openSearch = () => {
        this.setState({
            isSearchOpen: true,
        });

        // Scroll to top to fix ios safari keyboard hiding searchbar
        setTimeout(() => {
            scroll.scrollTo({ smooth: false, top: 0 });
        }, 100);

        scroll.preventScroll();
    };

    closeSearch = () => {
        this.setState({
            isSearchOpen: false,
        });
        scroll.allowScroll();
    };

    openMenu = () => {
        this.setState({
            isMenuOpen: true,
        });
        scroll.preventScroll();
    };

    closeMenu = () => {
        this.setState({
            isMenuOpen: false,
        });
        scroll.allowScroll();
    };

    keyPress = (e) => {
        const { history } = this.props;
        const term = e.target.value;
        const searchPath = getState('application').config?.options?.paths?.search;

        if (e.keyCode === 13) {
            history.push(`${searchPath}?q=${term}`);
            this.closeSearch();
        }
    };

    getMenuIds = (items) => {
        const ids = [];
        if (items && items.length) {
            items.forEach((item) => {
                const id = item.menu_link.link_id;
                if (ids.indexOf(id) === -1) {
                    ids.push(id);
                }
                if (item.sub_links) {
                    ids.push(...this.getMenuIds(item.sub_links));
                }
            });
        }
        return ids;
    };

    fetchMenuItems = async (menuLinks = []) => {
        try {
            // If this query get's to large, refactor to fetch only subitems when subitem is pressed
            const ids = this.getMenuIds(menuLinks);
            const locale = getState('application').locale.toLowerCase();
            const response = await Search(`posts_${locale}`, '', {
                facets: ['*'],
                facetFilters: [ids.map((id) => `external_id:${id}`)],
                hitsPerPage: ids.length,
            });
            this.setState({
                algoliaHits: response.results[0].hits,
            });
        } catch (error) {
            return [];
        }
    };

    render() {
        const { isMenuOpen, isSearchOpen, menuItems, transform } = this.state;
        const { application, basket, overlay, page, search, location, customer } = this.props;

        const showLoginButton = customer.auth || (!inServer && location.hash === '#login');

        const basketItemsCount =
            inServer || basket?.isEmpty()
                ? 0
                : basket?.items.map((items) => items?.quantity).reduce((a, b) => a + b, 0);
        const cookiesTotal = parseInt(Cookies.get('basket_items_amount'), 10) || 0;
        const isFetched = basket?.currency;
        const basketTotal = isFetched ? basketItemsCount : cookiesTotal;

        const handleBasketClick = () => {
            if (!isFetched && !basket.isFetching) {
                basket.getBasket();
            }
            overlay.show('basket_overlay');
        };

        const ruleContexts = application.config?.header?.search?.rule_contexts;

        const pageIsLoaded = page && page.data;
        const isCategoryGridPage =
            (pageIsLoaded && page.data.post_type === 'product_category') ||
            (pageIsLoaded && page.data.template === 'search');

        const decodedQuery = (query) => {
            let decodedQuery = query;
            try {
                decodedQuery = decodeURI(decodedQuery);
            } catch (e) {
                console.error(e);
            }

            return decodedQuery;
        };

        return (
            <Header>
                <Wrapper>
                    <Inner>
                        <Flex>
                            {!isSearchOpen && (
                                <Button p="8px" ml="-8px" onClick={this.openMenu}>
                                    <HamburgerIcon color={colors.black} width="24px" height="16px" />
                                </Button>
                            )}
                            {showLoginButton && <LoginButton theme="white" />}
                        </Flex>

                        <LogoLink to="/" onClick={this.closeSearch}>
                            <LogoIcon width="148px" height="24px" />
                        </LogoLink>

                        <Button ml="8px" onClick={handleBasketClick}>
                            <BasketIcon width="28px" height="24px" color={colors.black} />
                            {basketTotal > 0 && (
                                <Span color={colors.black} pt="2px" pl="7px">
                                    ({basketTotal})
                                </Span>
                            )}
                        </Button>

                        {isSearchOpen && (
                            <SearchWrapper top={isCategoryGridPage && transform ? '64px' : '78px'}>
                                <SearchContainer
                                    ruleContexts={(ruleContexts || []).map((rule) => rule.value)}
                                    query={decodedQuery(search.query)}
                                    companySpecificFilters={{ categoriesFilter: ['post_type:manufacturer_parts'] }}
                                    indices={{
                                        articles: { pageSize: 5 },
                                        categories: { pageSize: 5 },
                                        spareParts: { pageSize: 5 },
                                        suggestions: { pageSize: 5 },
                                        products: { pageSize: 6 },
                                    }}
                                    render={({ promise, renderProps, response }) => (
                                        <LoadingContainer
                                            promise={promise}
                                            renderProps={{ ...renderProps }}
                                            response={response}
                                            success={({
                                                articles,
                                                query,
                                                products,
                                                suggestions,
                                                categories,
                                                spareParts,
                                                hitsCount,
                                                totals,
                                                queryIds,
                                            }) => (
                                                <SearchContent
                                                    articles={articles}
                                                    categories={categories}
                                                    closeSearch={this.closeSearch}
                                                    hitsCount={hitsCount}
                                                    products={products}
                                                    queryIds={queryIds}
                                                    searchQuery={query}
                                                    spareParts={spareParts}
                                                    suggestions={suggestions}
                                                    totals={totals}
                                                    onSearch={this.siteSearch}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </SearchWrapper>
                        )}
                    </Inner>
                    <SearchInputWrapper onClick={!isSearchOpen ? this.openSearch : null}>
                        <SearchInput
                            basketTotal={basketTotal}
                            isActive={isSearchOpen}
                            maxLength={this.maxLength}
                            closeSearch={this.closeSearch}
                            keyPress={this.keyPress}
                            siteSearch={this.siteSearch}
                            transform={isCategoryGridPage && transform}
                        />
                    </SearchInputWrapper>
                </Wrapper>
                <Menu closeMenu={this.closeMenu} isMenuOpen={isMenuOpen} menuItems={menuItems} />
            </Header>
        );
    }
}

export default withRouter(
    injectModels(['overlay', 'basket', 'search', 'page', 'customer'])(withTranslation()(MobileNavbar)),
);
