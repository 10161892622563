import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';
import getRoleType from 'core-web/util/getRoleType';
import { below } from 'core-web/util/mediaqueries';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import SquareButton from 'theme/components/buttons/SquareButton';
import ProfileIcon from 'theme/components/icons/ProfileIcon';
import SpinnerIcon from 'theme/components/icons/SpinnerIcon';

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${colors.white};

    ${below.lg} {
        display: none;
    }
`;

const Span = styled('span')`
    font-size: 14px;
    line-height: 14px;

    &:first-of-type {
        margin-bottom: 4px;
    }
`;

const LoadingOverlay = styled('div')`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const LoginButton = ({ customer, overlay, theme }) => {
    const { t } = useTranslation();

    const { auth, companies, companyId, displayName, isFetching, roles } = customer;

    const company = companies.length && companyId ? companies.find((c) => c.id === companyId) : {};

    const roleType = roles.length > 0 && getRoleType(roles[0]);
    const roleTitle = roleType && roleType !== 'user' && t(`checkout_auth.roles.${getRoleType(roles[0])}`);

    return (
        <SquareButton
            disabled={isFetching}
            position="relative"
            height="46px"
            padding={['0 8px', null, '6px 16px']}
            textTransform="none"
            theme={theme || 'green'}
            marginLeft="12px"
            overflow="hidden"
            maxWidth={['224px', null, null, '100%']}
            onClick={() => overlay.show('customer_login')}
        >
            <ProfileIcon mr={['0', null, '16px']} color={colors.white} />
            <Column>
                <Span fontWeight={auth ? '400' : '700'}>{auth ? displayName : t('sign_in')}</Span>
                {auth && <Span>{company.name || roleTitle || t('checkout_auth.login.private')}</Span>}
            </Column>
            {isFetching && (
                <LoadingOverlay>
                    <SpinnerIcon />
                </LoadingOverlay>
            )}
        </SquareButton>
    );
};

LoginButton.propTypes = {
    customer: PropTypes.object.isRequired,
    overlay: PropTypes.object.isRequired,
    theme: PropTypes.string,
};

LoginButton.defaultProps = {
    theme: 'green',
};

export default injectModels(['customer', 'overlay'])(LoginButton);
