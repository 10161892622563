import Link from 'core-web/components/Link';
import useHover from 'core-web/libs/useHover';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const StyledLink = styled(Link)`
    display: block;
    color: ${colors.black};
    font-size: 13px;
    line-height: 14px;
    text-decoration: none;
    padding: 0 8px;
    border-left: 2px solid ${colors.white};
    flex: 1 1 100%;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center;
    & > div {
        display: flex;
        height: 46px;
        justify-content: center;
        align-items: center;
    }
    :first-of-type {
        border-left: none;
    }
    &:hover {
        background: ${colors.green};
    }

    @media only screen and (min-width: 1100px) {
        font-size: 14px;
        padding: 0 16px;
        white-space: nowrap;
    }
`;

const MenuItem = ({ text, url, onMouseEnter, onClick }) => {
    const [isHovering, hoverProps] = useHover();

    useEffect(() => {
        if (isHovering && onMouseEnter) {
            onMouseEnter();
        }
    }, [isHovering]);

    return (
        <StyledLink {...hoverProps} as={url ? StyledLink : 'a'} to={url} onClick={onClick}>
            <div>
                <span>{decodeHTMLEntities(text)}</span>
            </div>
        </StyledLink>
    );
};

MenuItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
    onMouseEnter: null,
};
export default MenuItem;
