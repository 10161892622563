import React from 'react';
import { injectModels } from 'core-web/state';
import PropTypes from 'prop-types';
import { getLanguages, getHreflangs, getGenericLink, getLinkWithSlug } from 'core-web/util/multimarket';
import withHeightAuto from 'core-web/components/withHeightAuto';
import styled from 'theme/libs/styled';
import Country from './Country';

const Countries = withHeightAuto(styled('div')`
    overflow: hidden;
    transition: height 200ms cubic-bezier(0.46, 0.88, 0.7, 0.83);
`);

const CountrySelector = ({ application, isOpen, page, ...rest }) => {
    const currentLocale = application.locale;
    const languages = getLanguages(application.languages, currentLocale);

    const alternateHrefs = page?.data?.alternates || null;
    let hreflangs;

    if (alternateHrefs) {
        hreflangs = getHreflangs(alternateHrefs, languages, page);
    }

    const countryLink = (locale) =>
        hreflangs?.length ? getLinkWithSlug(locale, hreflangs, languages) : getGenericLink(locale, languages);

    const selectedCountry = languages.find((i) => i.locale === application.locale) || 'sv_SE';
    const selectedCountryCode = selectedCountry.iso;

    return (
        <Countries height={isOpen ? 'auto' : '0'} {...rest}>
            {languages.map((lang, index) => {
                if (lang.locale === currentLocale) {
                    return null;
                }
                return (
                    <Country
                        language={lang}
                        langLink={countryLink(lang.locale)}
                        selected={selectedCountryCode === lang.iso}
                        key={`${lang.locale}-${index}`}
                    />
                );
            })}
        </Countries>
    );
};

CountrySelector.propTypes = {
    application: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    page: PropTypes.object.isRequired,
};

export default injectModels(['application', 'page'])(CountrySelector);
