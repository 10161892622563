import Link from 'core-web/components/Link';
import hocify from 'core-web/components/hocify';
import { inServer } from 'core-web/constants';
import LoadingContainer from 'core-web/containers/LoadingContainer';
import SearchContainer from 'core-web/containers/SearchContainer';
import useScrollbarWidth from 'core-web/hooks/useScrollbarWidth';
import { buildQueryString } from 'core-web/libs/grebbcommerce-api/util';
import scroll from 'core-web/libs/scroll';
import { injectModels } from 'core-web/state';
import getQueryParams from 'core-web/util/getQueryParams';
import { below } from 'core-web/util/mediaqueries';
import Cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BasketButton from 'theme/components/Header/Navbar/BasketButton';
import LoginButton from 'theme/components/Header/Navbar/LoginButton';
import LogoIcon from 'theme/components/icons/Logo';
import colors from 'theme/config/colors';
import variables from 'theme/config/variables';
import styled from 'theme/libs/styled';
import Menu from './Menu';
import SearchContent from './Search/SearchContent';
import SearchInput from './Search/SearchInput';
import Usp from './Usp';

const withScrollbarWidth = hocify(useScrollbarWidth);

const Div = styled('div')();

const HeaderInner = styled('div', {
    shouldForwardProp: (prop) => ['scrollbarWidth'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 160;
    width: 100%;
    background: ${colors.white};
    [data-prevent-scroll] & {
        ${({ scrollbarWidth }) => `padding-right: ${scrollbarWidth}px`};
    }
`;

const LogoLink = styled(Link)`
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 207px;
    height: 34px;
    &:hover {
        opacity: 1 !important;
    }
`;

const NavbarBg = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 200ms ease,
        visibility 200ms ease;
    .show-header-bg & {
        opacity: 1;
        visibility: visible;
    }
`;

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => ['auth'].indexOf(prop) === -1,
})`
    color: ${colors.black};
    font-size: 14px;
    font-weight: 700;
    transition: opacity 200ms ease;
    padding: 14px 0 14px 14px;
    &:hover {
        opacity: 0.8;
    }
    ${below.xl} {
        display: ${({ auth }) => (auth ? 'none' : 'normal')};
    }
`;

const GhostWrapper = styled('div')`
    margin-top: 161px;
`;

class DesktopNavbar extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        menuLinks: PropTypes.array,
        overlay: PropTypes.object,
        query: PropTypes.string.isRequired,
        scrollbarWidth: PropTypes.number.isRequired,
        search: PropTypes.object,
        uspBanner: PropTypes.object,
    };

    static defaultProps = {
        menuLinks: [],
        overlay: {},
        search: {},
        uspBanner: {},
    };

    static decodedQuery = (query) => {
        let decodedQuery = query;
        try {
            decodedQuery = decodeURI(decodedQuery);
        } catch (e) {
            console.error(e);
        }

        return decodedQuery;
    };

    constructor(props) {
        super(props);

        this.state = {
            showSearch: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { history, location, application, basket, search, menuLinks, uspBanner, customer } = this.props;
        const { showSearch } = this.state;
        return (
            JSON.stringify(history) !== JSON.stringify(nextProps.history) ||
            JSON.stringify(location) !== JSON.stringify(nextProps.location) ||
            JSON.stringify(application) !== JSON.stringify(nextProps.application) ||
            JSON.stringify(basket) !== JSON.stringify(nextProps.basket) ||
            JSON.stringify(search) !== JSON.stringify(nextProps.search) ||
            JSON.stringify(menuLinks) !== JSON.stringify(nextProps.menuLinks) ||
            JSON.stringify(uspBanner.usps) !== JSON.stringify(nextProps.uspBanner.usps) ||
            JSON.stringify(customer.auth) !== JSON.stringify(nextProps.customer.auth) ||
            showSearch !== nextState.showSearch
        );
    }

    closeSearch = () => {
        this.setState({
            showSearch: false,
        });
        scroll.allowScroll();
        document.body.classList.remove('show-header-bg');
    };

    searchInputFocus = () => {
        this.setState({
            showSearch: true,
        });
        scroll.preventScroll();
        document.body.classList.add('show-header-bg');
    };

    siteSearch = (query) => {
        const { search, history, location } = this.props;

        const params = getQueryParams(location);
        let locationClone = cloneDeep(location);

        if (Object.prototype.hasOwnProperty.call(params, 'page') && !inServer) {
            const updatedQueryParams = `?${buildQueryString(params)}`;
            locationClone = { ...locationClone, search: updatedQueryParams };
        }
        search.search(query, history, locationClone);

        if (query && query.length < 1) {
            search.clear(history, location);
        }
    };

    render() {
        const { showSearch } = this.state;
        const { application, overlay, basket, menuLinks, uspBanner, search, scrollbarWidth, location, customer } =
            this.props;

        const basketItemsCount =
            inServer || basket?.isEmpty()
                ? 0
                : basket?.items.map((items) => items?.quantity).reduce((a, b) => a + b, 0);
        const cookiesTotal = parseInt(Cookies.get('basket_items_amount'), 10) || 0;
        const isFetched = basket?.currency;
        const basketTotal = isFetched ? basketItemsCount : cookiesTotal;

        const handleBasketClick = () => {
            if (!isFetched && !basket.isFetching) {
                basket.getBasket();
            }
            overlay.show('basket_overlay');
        };

        const customerService = application.config?.header?.menu_links?.customer_service || {};
        const ruleContexts = application.config?.header?.search?.rule_contexts;

        const showLoginButton = customer.auth || (!inServer && location.hash === '#login');

        return (
            <header>
                <HeaderInner scrollbarWidth={scrollbarWidth}>
                    <Usp usps={uspBanner.usps} />
                    <Div position="relative" margin="0 auto" p="12px 16px" maxWidth={variables.maxWidth}>
                        <Div
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            bg={colors.white}
                            height="46px"
                            mb="14px"
                            mx="auto"
                            position="relative"
                            style={{
                                zIndex: '12',
                            }}
                        >
                            <SearchInput
                                closeSearch={this.closeSearch}
                                isSearchOpen={showSearch}
                                onSearch={this.siteSearch}
                                onInputFocus={this.searchInputFocus}
                            />

                            <LogoLink to="/" onClick={this.closeSearch}>
                                <LogoIcon />
                            </LogoLink>

                            <Div display="flex" alignItems="center">
                                {customerService.url && (
                                    <StyledLink auth={customer.auth} to={customerService.url}>
                                        {customerService.text}
                                    </StyledLink>
                                )}
                                <Div>{showLoginButton && <LoginButton theme="green" />}</Div>
                                <Div>
                                    <BasketButton basketTotal={basketTotal} onClick={handleBasketClick} />
                                </Div>
                            </Div>
                        </Div>

                        {menuLinks && <Menu items={menuLinks} isSearchOpen={showSearch} />}

                        {showSearch && (
                            <SearchContainer
                                ruleContexts={(ruleContexts || []).map((rule) => rule.value)}
                                query={DesktopNavbar.decodedQuery(search.query)}
                                companySpecificFilters={{ categoriesFilter: ['post_type:manufacturer_parts'] }}
                                indices={{
                                    articles: { pageSize: 5 },
                                    categories: { pageSize: 5 },
                                    spareParts: { pageSize: 8 },
                                    suggestions: { pageSize: 6 },
                                    products: { pageSize: 8 },
                                }}
                                render={({ promise, renderProps, response }) => (
                                    <LoadingContainer
                                        promise={promise}
                                        renderProps={{ ...renderProps }}
                                        response={response}
                                        success={({
                                            articles,
                                            query,
                                            products,
                                            suggestions,
                                            categories,
                                            spareParts,
                                            totals,
                                            queryIds,
                                        }) => (
                                            <SearchContent
                                                articles={articles}
                                                categories={categories}
                                                closeSearch={this.closeSearch}
                                                products={products}
                                                searchQuery={query}
                                                queryIds={queryIds}
                                                spareParts={spareParts}
                                                suggestions={suggestions}
                                                totals={totals}
                                                scrollbarWidth={scrollbarWidth}
                                                onSearch={this.siteSearch}
                                            />
                                        )}
                                    />
                                )}
                            />
                        )}
                    </Div>
                </HeaderInner>
                <NavbarBg onClick={this.closeSearch} />
                <GhostWrapper />
            </header>
        );
    }
}

export default withRouter(injectModels(['overlay', 'basket', 'search', 'customer'])(withScrollbarWidth(DesktopNavbar)));
