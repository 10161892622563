import { injectModels } from 'core-web/state';
import { below } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BigArrowIcon from 'theme/components/icons/BigArrowIcon';
import CrossIcon from 'theme/components/icons/CrossIcon';
import SearchIcon from 'theme/components/icons/SearchIcon';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const excludedProps = ['active', 'activeWidth', 'isActive', 'basketTotal', 'setTimeout', 'transform'];

const SearchInputWrapper = styled('div')`
    height: 62px;
    background-color: ${colors.white};
    padding: 0 16px 16px;
    transition: 0.15s ease;

    ${(props) =>
        props.transform &&
        `
        height: 0;
        padding: 0;
    `}
`;

const SearchWrapper = styled('div', {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    ${below.xl} {
        ${(props) =>
            props.active &&
            `
        width: calc(100% - 72px);
        left: 40px;
    `}
    }

    position: absolute;
    left: 0;
    right: 0;
    z-index: 99999;
    height: 46px;
    overflow: hidden;
    width: calc(100% - 32px);
    margin: 8px 16px;
    top: 8px;
    transition: 0.15s ease;

    ${(props) =>
        !props.active &&
        `
        top: 58px;
    `}

    ${(props) =>
        props.transform &&
        `
        top: 1px;
        left: 40px;
        transition: 0.15s ease;
    `}

    ${(props) =>
        props.transform &&
        props.basketTotal === 0 &&
        `
        width: calc(100% - 115px);
    `}

    ${(props) =>
        props.transform &&
        props.basketTotal > 0 &&
        `
        width: calc(100% - 141px);
    `}

    ${(props) =>
        props.transform &&
        props.basketTotal > 9 &&
        `
        width: calc(100% - 149px);
    `}

    ${(props) =>
        props.transform &&
        props.basketTotal > 99 &&
        `
        width: calc(100% - 159px);
    `}
`;

const StyledSearchInput = styled('input')`
    ${below.xl} {
        padding: 0px 50px 0px 16px;
    }

    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    padding: 0px 50px 0px 45px;
    margin: 0;
    background-color: ${colors.lightGrey};
    font-size: 16px;
    border: 1px solid ${colors.green};
    transition: all 0.1s ease-out;
    box-shadow: none;

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        display: none;
    }

    &[type='text'],
    &[type='number'] {
        font-size: 16px;
    }

    &:focus {
        box-shadow: none;
        outline: none !important;
        border: 2px solid ${colors.green};
    }

    &::-ms-clear {
        display: none;
    }

    &::placeholder {
        padding-top: 3px;
        color: ${colors.textGrey};
    }

    &::-webkit-input-placeholder {
        opacity: 1;
    }
`;

const StyledSearchButton = styled('button')`
    position: absolute;
    right: 2px;
    top: 0px;
    border: none;
    background: none;
    cursor: pointer;
    width: 46px;
    height: 46px;
`;

const StyledCloseButton = styled('button')`
    position: absolute;
    right: 0px;
    top: -1px;
    height: 100%;
    margin-left: auto;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0 16px;
`;

const StyledBackButton = styled('button', {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    position: relative;
    left: 0px;
    bottom: 48px;
    height: 100%;
    margin-left: auto;
    border: none;
    background: none;
    cursor: pointer;
    ${(props) =>
        !props.active &&
        `
        bottom: 0;
        display: none;
    `}
`;

class SearchInput extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        basketTotal: PropTypes.number,
        closeSearch: PropTypes.func,
        isActive: PropTypes.bool,
        keyPress: PropTypes.func,
        query: PropTypes.string,
        search: PropTypes.object.isRequired,
        siteSearch: PropTypes.func,
        t: PropTypes.func.isRequired,
        transform: PropTypes.bool,
    };

    static defaultProps = {
        basketTotal: 0,
        closeSearch: null,
        isActive: false,
        keyPress: null,
        query: '',
        siteSearch: null,
        transform: false,
    };

    constructor(props) {
        super(props);

        const { search } = props;

        this.state = {
            query: search.query,
            hideSearch: false,
        };
    }

    handleChange = (value) => {
        this.setState({ query: value });
    };

    siteSearch = (query) => {
        const { siteSearch } = this.props;
        let hideSearch = false;
        siteSearch(query);
        if (query.length > 0) {
            hideSearch = true;
        }
        this.setState({ hideSearch });
    };

    clearSearch = () => {
        const { siteSearch } = this.props;
        siteSearch('');
        this.focusInput();
        this.setState({ query: '', hideSearch: false });
    };

    // eslint-disable-next-line react/no-unused-class-component-methods -- false positive?
    blurInput = () => {
        if (this.inputRef.current) {
            this.inputRef.current.blur();
        }
    };

    // eslint-disable-next-line class-methods-use-this -- false positive?
    focusInput = () => {
        const searchInput = document.getElementById('searchField');
        searchInput.focus();
    };

    render() {
        const { application, basketTotal, isActive, keyPress, t, transform, closeSearch } = this.props;
        const { query, hideSearch } = this.state;
        this.inputRef = React.createRef();
        const searchQuery = query ?? '';
        const searchPlaceholderText = application.config?.header?.search?.placeholder_text;
        const placeholderText = searchPlaceholderText?.length ? searchPlaceholderText : t('search_placeholder');

        const decodedQuery = (query) => {
            let decodedQuery = query;
            try {
                decodedQuery = decodeURI(decodedQuery);
            } catch (e) {
                console.error(e);
            }

            return decodedQuery;
        };

        return (
            <SearchInputWrapper transform={transform}>
                <StyledBackButton
                    aria-label={t('close_searchinput')}
                    active={isActive}
                    transform={transform}
                    onClick={closeSearch}
                >
                    <BigArrowIcon width="26px" height="24px" color={colors.darkGrey} direction="left" />
                </StyledBackButton>
                <SearchWrapper active={isActive} transform={transform} basketTotal={basketTotal}>
                    <StyledSearchInput
                        type="search"
                        ref={this.inputRef}
                        enterkeyhint="search"
                        name="searchField"
                        id="searchField"
                        my={0}
                        height="100%"
                        width="100%"
                        borderRadius="4px"
                        placeholder={placeholderText}
                        autoComplete="off"
                        value={decodedQuery(searchQuery)}
                        onChange={(e) => {
                            this.handleChange(e.target.value);
                            this.siteSearch(e.target.value);
                        }}
                        onKeyDown={(e) => keyPress(e)}
                    />
                    {!hideSearch && (
                        <StyledSearchButton aria-label={t('searchicon')} onClick={this.focusInput}>
                            <SearchIcon color={colors.black} width="16px" height="16px" />
                        </StyledSearchButton>
                    )}

                    {isActive && (
                        <StyledCloseButton
                            aria-label={t('close_searchinput')}
                            display={!hideSearch ? 'none' : ''}
                            onClick={this.clearSearch}
                        >
                            <CrossIcon width="14px" height="14px" color={colors.black} />
                        </StyledCloseButton>
                    )}
                </SearchWrapper>
            </SearchInputWrapper>
        );
    }
}
export default withRouter(injectModels(['application', 'search'])(withTranslation()(SearchInput)));
