// eslint-disable-next-line eslint-comments/disable-enable-pair -- ??
/* eslint-disable jsx-a11y/label-has-associated-control -- disable all a11y rules for now */
import { injectModels } from 'core-web/state';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CrossIcon from 'theme/components/icons/CrossIcon';
import SearchIcon from 'theme/components/icons/SearchIcon';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const SearchInputWrapper = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 4;
    height: 46px;
    max-width: 344px;
    border: solid ${colors.green} 1px;
    border-radius: 4px;
`;

const SearchTextInput = styled('input')`
    position: absolute;
    top: 0;
    left: 0;

    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 22px;

    background-color: ${colors.white};
    border-radius: 4px;
    border: none;
    box-shadow: none;
    transition: all 0.1s;

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        display: none;
    }

    &:focus {
        background: ${colors.white};
        box-shadow: none;
        outline: none !important;
        border: solid ${colors.green} 1px;
    }
    &::-ms-clear {
        display: none;
    }
    &::-webkit-input-placeholder {
        opacity: 1;
        color: ${colors.textGrey};
    }
`;

const SearchIconWrapper = styled('div')`
    position: absolute;
    line-height: 13px;
    top: calc(50% - 8px);
    cursor: pointer;
    left: 16px;
`;
const SearchClear = styled('div')`
    position: absolute;
    top: calc(50% - 8px);
    cursor: pointer;
    right: 16px;
`;

class SearchInput extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        closeSearch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isSearchOpen: PropTypes.bool,
        maxLength: PropTypes.string,
        onInputFocus: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        search: PropTypes.object.isRequired,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isSearchOpen: false,
        maxLength: '50',
    };

    static focusInput = () => {
        const searchInput = document.getElementById('searchField');
        searchInput.focus();
    };

    constructor(props) {
        super(props);

        const { onSearch, search } = props;

        this.onSearch = debounce((query) => onSearch(query), 350);

        this.state = {
            query: search.query,
        };
    }

    handleOnChange(query) {
        this.setState({ query });
        this.onSearch(query);
    }

    handleKeyPress = (e) => {
        const { application, history, closeSearch } = this.props;
        const term = e.target.value;
        const searchPath = application.config?.options?.paths?.search;

        if (e.keyCode === 13) {
            // Enter
            e.target.blur();
            history.push(`${searchPath}?q=${term}`);
            closeSearch();
        }
        if (e.keyCode === 27) {
            // ESC
            e.target.blur();
            e.preventDefault();
            closeSearch();
        }
    };

    handleOnFocus = () => {
        const { onInputFocus } = this.props;

        if (onInputFocus) {
            onInputFocus();
        }
    };

    clearSearch = () => {
        const { onSearch, closeSearch } = this.props;
        onSearch('');
        closeSearch();
        this.setState({ query: '' });
    };

    render() {
        const { application, maxLength, isSearchOpen, t } = this.props;
        const { query } = this.state;

        const searchPlaceholderText = application.config?.header?.search?.placeholder_text;
        const placeholderText = searchPlaceholderText?.length ? searchPlaceholderText : t('search_placeholder');

        const decodedQuery = (query) => {
            let decodedQuery = query;
            try {
                decodedQuery = decodeURI(decodedQuery);
            } catch (e) {
                console.error(e);
            }

            return decodedQuery;
        };

        return (
            <SearchInputWrapper>
                <label style={{ position: 'absolute', left: '-10000px' }} htmlFor="searchField" name="searchField">
                    searchField
                </label>
                <SearchTextInput
                    type="search"
                    enterkeyhint="search"
                    id="searchField"
                    placeholder={placeholderText}
                    maxLength={maxLength}
                    autoComplete="off"
                    value={decodedQuery(query)}
                    onFocus={this.handleOnFocus}
                    onChange={(e) => this.handleOnChange(e.target.value)}
                    onKeyDown={(e) => this.handleKeyPress(e)}
                />
                <SearchIconWrapper onClick={SearchInput.focusInput}>
                    <SearchIcon width="16px" height="16px" />
                </SearchIconWrapper>
                {isSearchOpen && (
                    <SearchClear onClick={this.clearSearch}>
                        <CrossIcon width="12px" height="12px" marginLeft="8px" />
                    </SearchClear>
                )}
            </SearchInputWrapper>
        );
    }
}

export default withRouter(injectModels(['application', 'search'])(withTranslation()(SearchInput)));
