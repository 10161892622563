import { useRef, useState } from 'react';

export interface UseHoverOptions {
    mouseEnterDelayMS?: number;
    mouseLeaveDelayMS?: number;
    callback?: (x: boolean) => void;
}

export type HoverProps = Pick<React.HTMLAttributes<HTMLElement>, 'onMouseEnter' | 'onMouseLeave'>;

export default function useHover({ mouseEnterDelayMS = 200, mouseLeaveDelayMS = 0, callback }: UseHoverOptions = {}): [
    boolean,
    HoverProps,
] {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const mouseEnterTimer = useRef<any | undefined>(null);
    const mouseOutTimer = useRef<any | undefined>(null);
    const fn = callback || setIsHovering;

    return [
        isHovering,
        {
            onMouseEnter: () => {
                clearTimeout(mouseOutTimer.current);

                mouseEnterTimer.current = setTimeout(() => fn(true), mouseEnterDelayMS);
            },
            onMouseLeave: () => {
                clearTimeout(mouseEnterTimer.current);

                mouseOutTimer.current = setTimeout(() => fn(false), mouseLeaveDelayMS);
            },
        },
    ];
}
