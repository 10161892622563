import withHeightAuto from 'core-web/components/withHeightAuto';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import DropDownMenuItem from './DropDownMenuItem';

const Wrapper = styled('div')`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-top: 2px;
    overflow: hidden;
    transition: height 200ms ease-in-out;
    height: 0;

    &.open {
        animation: toggleOverflow 0ms forwards 200ms;

        @keyframes toggleOverflow {
            0% {
                overflow: hidden;
            }
            100% {
                overflow: visible;
            }
        }
    }
`;

const WrapperWithAnimation = withHeightAuto(Wrapper);

const Inner = styled('div')`
    display: flex;
    background-color: ${colors.bgGrey};
    margin: 0 auto;
    overflow-y: auto;
    max-height: calc(85vh - 162px);
`;

const Column = styled('div')`
    width: 25%;
    border-right: 1px solid ${colors.grey};
    padding: 0 24px 32px;
`;

const createColumns = (items) => {
    if (!items) {
        return [];
    }

    const columns = [];
    let k = 0;

    const itemsInColum = items.length ? Math.floor(items.length / 4) : 0;
    const itemsModulus = items.length ? items.length % 4 : 0;

    for (let i = 0; i < 4; i++) {
        const menuItems = [];

        for (let j = 0; j < itemsInColum; j++) {
            if (items[k]) {
                menuItems.push(items[k]);
            }
            k += 1;
        }

        if (i < itemsModulus) {
            if (items[k]) {
                menuItems.push(items[k]);
            }
            k += 1;
        }

        columns.push(menuItems);
    }

    return columns;
};

const DropDownMenu = ({ items, parentTabIndex, closeMenu }) => (
    <WrapperWithAnimation height={items ? 'auto' : '0'} className={items ? 'open' : ''}>
        <Inner>
            {createColumns(items).map((column, i) => (
                <Column key={i}>
                    {column.map((item, j) => (
                        <DropDownMenuItem key={j} item={item} closeMenu={closeMenu} parentTabIndex={parentTabIndex} />
                    ))}
                </Column>
            ))}
        </Inner>
    </WrapperWithAnimation>
);

DropDownMenu.propTypes = {
    closeMenu: PropTypes.func,
    items: PropTypes.array,
    parentTabIndex: PropTypes.number,
};

DropDownMenu.defaultProps = {
    closeMenu: null,
    items: null,
    parentTabIndex: null,
};

export default DropDownMenu;
