import Link from 'core-web/components/Link';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CountrySelector from 'theme/components/country/CountrySelector';
import SelectedCountry from 'theme/components/country/SelectedCountry';
import ChevronIcon from 'theme/components/icons/ChevronIcon';
import CrossIcon from 'theme/components/icons/CrossIcon';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Div = styled('div')();
const VATWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const StyledButton = styled('button')`
    outline: none;
    border: none;
    border-radius: 4px;
    line-height: 16px;
    font-size: 16px;
    transition: all 0.2s ease-out;
    background-color: ${colors.white};
    border: 1px solid ${colors.green};
    color: ${colors.black};
    padding: 7px 12px;

    &.active {
        background-color: ${colors.green};
        border: none;
        color: ${colors.white};
        font-weight: 700;
        padding: 8px 12px;
    }
`;

const StyledInklButton = styled(StyledButton)`
    margin-right: -2px;
`;

const StyledExklButton = styled(StyledButton)`
    margin-left: -2px;
`;

const BackLinkFlex = styled('div')`
    display: flex;
    align-items: center;
`;

const LinkBanner = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${colors.grey};
`;

const IconBox = styled('div')`
    position: absolute;
    right: -52px;
    top: 12px;
    height: 40px;
    width: 40px;
    background-color: ${colors.white};
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: 0.2s;
    opacity: 1;
    transform: scale(1);

    .closed & {
        transform: scale(0);
        opacity: 0;
    }
`;

const MenuInfoText = styled('div')`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    font-weight: 700;
    text-align: center;
    transform: translate(-50%, -50%);
`;

const MenuHeader = ({
    showVat,
    toggleVat,
    updateBasketCompanyInfo,
    isMenuFooterShown,
    isShown,
    menuItems,
    stateHistory,
    goBackMenu,
    closeMenu,
    menuInfoText,
    menuInfoUrl,
    showCountrySelector,
    showCountries,
    toggleCountrySelector,
}) => {
    const { t } = useTranslation();

    return (
        <Div width="100%">
            {isMenuFooterShown && (
                <Div borderBottom={`1px solid ${colors.grey}`} p="16px 16px" width="100%">
                    <VATWrapper>
                        <Div mr="auto">
                            {showCountrySelector && (
                                <SelectedCountry isOpen={showCountries} toggleCountrySelector={toggleCountrySelector} />
                            )}
                        </Div>

                        <StyledInklButton
                            className={showVat ? 'active' : ''}
                            onClick={() => {
                                if (!showVat) {
                                    toggleVat();
                                }
                                updateBasketCompanyInfo();
                            }}
                        >
                            {t('checkout.private')}
                        </StyledInklButton>

                        <StyledExklButton
                            className={!showVat ? 'active' : ''}
                            onClick={() => {
                                if (showVat) {
                                    toggleVat();
                                }
                                updateBasketCompanyInfo();
                            }}
                        >
                            {t('checkout.business')}
                        </StyledExklButton>
                    </VATWrapper>
                </Div>
            )}
            {showCountrySelector && <CountrySelector isOpen={showCountries} />}
            {menuItems && stateHistory.length > 0 && (
                // TODO: need to know when we are at the last segment in the menu. to show the image
                <Div bg={colors.green} color={colors.white} width="100%" position="relative">
                    <BackLinkFlex
                        p="25px 16px"
                        fontSize="16px"
                        onClick={(e) => {
                            e.preventDefault();
                            goBackMenu();
                        }}
                    >
                        <ChevronIcon direction="left" />
                        <MenuInfoText position="absolute" left="0" width="100%" fontWeight="700" textAlign="center">
                            {decodeHTMLEntities(menuInfoText)}
                        </MenuInfoText>
                    </BackLinkFlex>
                </Div>
            )}

            <IconBox onClick={closeMenu}>
                <CrossIcon width="14px" height="14px" />
            </IconBox>

            {isShown && (
                <LinkBanner p="16px" to={menuInfoUrl} onClick={closeMenu}>
                    <Div
                        color={colors.white}
                        bg={colors.green}
                        borderRadius="54px"
                        fontWeight="800"
                        p="11px 16px"
                        fontSize="14px"
                        lineHeight="16px"
                        textAlign="center"
                    >
                        <Div textTransform="uppercase">
                            {t('show_all_within')} {decodeHTMLEntities(menuInfoText)}
                        </Div>
                    </Div>
                </LinkBanner>
            )}
        </Div>
    );
};

MenuHeader.propTypes = {
    closeMenu: PropTypes.func,
    goBackMenu: PropTypes.func,
    isMenuFooterShown: PropTypes.bool,
    isShown: PropTypes.bool,
    menuInfoText: PropTypes.string,
    menuInfoUrl: PropTypes.string,
    menuItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    showCountries: PropTypes.bool,
    showCountrySelector: PropTypes.bool,
    showVat: PropTypes.bool,
    stateHistory: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    toggleCountrySelector: PropTypes.func.isRequired,
    toggleVat: PropTypes.func,
    updateBasketCompanyInfo: PropTypes.func,
};

MenuHeader.defaultProps = {
    closeMenu: null,
    goBackMenu: null,
    isMenuFooterShown: false,
    isShown: false,
    menuInfoText: '',
    menuInfoUrl: '',
    menuItems: null,
    showCountries: false,
    showCountrySelector: true,
    showVat: false,
    stateHistory: null,
    toggleVat: null,
    updateBasketCompanyInfo: null,
};

export default MenuHeader;
