import { STORM_ROLE_ADMIN, STORM_ROLE_SALE } from 'theme/config/constants';

const getRoleType = (role) => {
    const roleAsInt = parseInt(role, 10);

    switch (roleAsInt) {
        case parseInt(STORM_ROLE_SALE, 10):
            return 'seller';
        case parseInt(STORM_ROLE_ADMIN, 10):
            return 'admin';
        default:
            return 'user';
    }
};

export default getRoleType;
