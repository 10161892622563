import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';
import { above } from 'core-web/util/mediaqueries';
import { getLanguages } from 'core-web/util/multimarket';
import colors from 'theme/config/colors';
import variables from 'theme/config/variables';
import styled from 'theme/libs/styled';
import CountrySelector from 'theme/components/country/CountrySelector';
import SelectedCountry from 'theme/components/country/SelectedCountry';
import CheckIcon from 'theme/components/icons/CheckIcon';

const UspRow = styled('div')`
    position: relative;
    z-index: 160;
    background-color: ${colors.green};
    color: ${colors.white};
`;

const Inner = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;

    height: 32px;
    max-width: ${variables.maxWidth};
    padding: 0 16px;
    background-color: ${colors.green};
`;

const Usps = styled('div')`
    display: flex;
`;

const UspText = styled('span')`
    margin: 0 16px 0 8px;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    ${above.xl} {
        margin-right: 24px;
    }
`;

const Right = styled('div')`
    position: absolute;
    top: 0;
    right: 16px;
    height: 100%;

    display: flex;
    align-items: center;
`;
const VatBox = styled('div')`
    span {
        display: inline-block;
        position: relative;
        top: -1px;
    }
    &.disabled {
        &:hover > div {
            opacity: 1;
        }

        & > span {
            pointer-events: none;
        }
    }
`;

const VatText = styled('span', {
    shouldForwardProp: (prop) => ['active'].indexOf(prop) === -1,
})`
    color: ${colors.white};
    cursor: pointer;
    font-size: 14px;
    line-height: 1em;
    &.active {
        cursor: default;
        font-weight: bold;
        text-decoration: underline;
        pointer-events: none;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const Tooltip = styled('div')`
    display: block;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    left: 80px;
    top: 36px;
    padding: 12px;
    border-radius: 4px;
    color: ${colors.black};
    border: 1px solid ${colors.grey};
    background-color: ${colors.lightGrey};
    transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
    min-width: 220px;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        border: 10px solid ${colors.lightGrey};
        width: 0;
        height: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
    }
`;

const Countries = styled('div')`
    position: absolute;
    right: 0;
    top: 100%;
    background: ${colors.white};
    border-radius: 0px 0px 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    &.is-open {
        border: 1px solid ${colors.grey};
    }
`;

const BlurBackground = styled('div')`
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 159;

    &.is-open {
        display: inline;
    }
`;

const Usp = ({ application, basket, customer, products, usps }) => {
    const { t } = useTranslation();
    const [countriesOpen, setCountriesOpen] = useState(false);
    const { companies = [] } = customer || {};

    const toggleVat = (trigger) => {
        if (trigger) {
            products.toggleVat();
            basket.updateBasketCompanyInfo();
        }
    };

    const toggleCountrySelector = () => setCountriesOpen(!countriesOpen);

    const basketIsLoading = basket.isModifyingBasket || basket.isLoading;

    const languages = getLanguages(application.languages, application.locale);
    const showCountrySelector = languages?.length > 1;

    return (
        <>
            <UspRow>
                <Inner>
                    <Usps>
                        {(usps || []).map((usp, index) => (
                            <div key={index}>
                                <CheckIcon width="14px" height="12px" />
                                <UspText>{usp.text}</UspText>
                            </div>
                        ))}
                    </Usps>

                    <Right>
                        <VatBox className={companies.length ? 'disabled' : ''}>
                            <VatText
                                className={`${products.showVat ? 'active' : ''} ${basketIsLoading ? 'disabled' : ''}`}
                                onClick={() => !basketIsLoading && toggleVat(!products.showVat)}
                            >
                                {t('checkout.private')}
                            </VatText>
                            <VatText mx="8px">|</VatText>
                            <VatText
                                className={`${!products.showVat ? 'active' : ''} ${basketIsLoading ? 'disabled' : ''}`}
                                onClick={() => !basketIsLoading && toggleVat(products.showVat)}
                            >
                                {t('checkout.business')}
                            </VatText>
                            <Tooltip>
                                <span>{t('checkout_auth.messages.vat_select')}</span>
                            </Tooltip>
                        </VatBox>

                        {showCountrySelector && (
                            <>
                                <SelectedCountry
                                    ml="24px"
                                    isOpen={countriesOpen}
                                    toggleCountrySelector={toggleCountrySelector}
                                />
                                <Countries className={countriesOpen ? 'is-open' : ''}>
                                    <CountrySelector isOpen={countriesOpen} />
                                </Countries>
                            </>
                        )}
                    </Right>
                </Inner>
            </UspRow>
            <BlurBackground className={countriesOpen ? 'is-open' : ''} onClick={toggleCountrySelector} />
        </>
    );
};

Usp.propTypes = {
    application: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    usps: PropTypes.array,
};

Usp.defaultProps = {
    usps: [],
};

export default injectModels(['application', 'customer', 'products', 'basket'])(Usp);
