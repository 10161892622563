// eslint-disable-next-line eslint-comments/disable-enable-pair -- ??
/* eslint-disable jsx-a11y/no-static-element-interactions -- disable all a11y rules for now */
// eslint-disable-next-line eslint-comments/disable-enable-pair -- ??
/* eslint-disable jsx-a11y/click-events-have-key-events -- disable all a11y rules for now */
import { Search } from 'core-web/libs/Algolia-v2';
import { injectModels } from 'core-web/state';
import get from 'core-web/util/get';
import { getLanguages } from 'core-web/util/multimarket';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import MenuFooter from './MenuFooter';
import MenuHeader from './MenuHeader';
import MenuItems from './MenuItems';

const MenuWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    z-index: 100000;

    pointer-events: none;
    transition: all 200ms ease-in-out;

    .menuBg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        transition: opacity 350ms linear;
        opacity: 0;
    }

    &.open {
        left: 0%;
        pointer-events: auto;
        .menuBg {
            opacity: 1;
        }
    }
`;

const Inner = styled('div')`
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    width: calc(100vw - 64px);
    max-width: 720px;
    height: 100%;
`;

const MenuContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
`;

class Menu extends Component {
    fetchedMenuItems = {};

    static propTypes = {
        application: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        closeMenu: PropTypes.func.isRequired,
        isMenuOpen: PropTypes.bool,
        menuItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };

    static defaultProps = {
        isMenuOpen: false,
        menuItems: null,
        products: null,
    };

    constructor(props) {
        super(props);

        const { menuItems } = props;

        this.state = {
            activeItem: null,
            currentItem: null,
            menuItems,
            showCountries: false,
            stateHistory: [],
        };
    }

    itemClick = async (index, item) => {
        let subItems = item.sub_links;
        const currentState = { ...this.state };
        const newHistory = currentState.stateHistory;
        newHistory.splice(0, 0, currentState);

        if (subItems) {
            const itemLinkId = `${item.menu_link.link_id}_${index}`;
            if (this.fetchedMenuItems[itemLinkId]) {
                subItems = this.fetchedMenuItems[itemLinkId];
            } else {
                try {
                    const hits = await this.algoliaSearch(item.sub_links.map((sub) => sub.menu_link.link_id));
                    if (hits.length) {
                        this.fetchedMenuItems[itemLinkId] = item.sub_links.reduce((acc, cur) => {
                            if (!cur?.menu_link?.url || !cur?.menu_link?.text) {
                                return acc;
                            }

                            const hit = hits.find((hit) => hit?.external_id === parseInt(cur.menu_link.link_id, 10));
                            cur.thumbnail = hit?.settings?.thumbnail?.url || hit?.hero_content?.data?.image?.url;

                            return [...acc, cur];
                        }, []);

                        subItems = this.fetchedMenuItems[itemLinkId];
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }

        this.setState({
            activeItem: index,
            currentItem: item,
            menuItems: subItems,
            showCountries: false,
            stateHistory: newHistory,
        });
    };

    algoliaSearch = async (ids) => {
        try {
            const { application } = this.props;
            const locale = application.locale.toLowerCase();
            const response = await Search(`posts_${locale}`, '', {
                facets: ['*'],
                facetFilters: [ids.map((id) => `external_id:${id}`)],
                hitsPerPage: ids.length,
            });
            return response.results[0].hits;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    closeMenu = () => {
        const { menuItems, closeMenu } = this.props;
        this.setState(
            {
                activeItem: -1,
                currentItem: null,
                menuItems,
                showCountries: false,
                stateHistory: [],
            },
            closeMenu,
        );
    };

    goBackMenu = () => {
        // eslint-disable-next-line react/no-access-state-in-setstate -- needed to create a copy of the state
        const stateCopy = { ...this.state };
        const prespliceShite = [...stateCopy.stateHistory];
        prespliceShite.splice(0, 1);
        this.setState({
            activeItem: stateCopy.stateHistory[0].activeItem,
            currentItem: stateCopy.stateHistory[0].currentItem,
            menuItems: stateCopy.stateHistory[0].menuItems,
            stateHistory: prespliceShite,
        });
    };

    toggleCountrySelector = () => {
        this.setState((prevState) => ({ showCountries: !prevState.showCountries }));
    };

    render() {
        const { menuItems, currentItem, stateHistory, activeItem, showCountries } = this.state;
        const { application, basket, isMenuOpen, products } = this.props;
        const menuInfo = currentItem && currentItem.menu_link;
        const isShown = stateHistory.length > 0;
        const isMenuFooterShown = stateHistory.length < 1;

        const contactPage = get(application, 'config.header.menu_links.contact_page');
        const contactPageUrl = contactPage && contactPage.url;
        const contactPageText = contactPage && contactPage.text;

        const returnPage = get(application, 'config.header.menu_links.returns_page');
        const returnPageUrl = returnPage && returnPage.url;
        const returnPageText = returnPage && returnPage.text;

        const menuInfoUrl = menuInfo && menuInfo.url;
        const menuInfoText = menuInfo && menuInfo.text;

        const languages = getLanguages(application.languages, application.locale);
        const showCountrySelector = languages?.length > 1;

        return (
            <MenuWrapper className={isMenuOpen ? 'open' : 'closed'}>
                <Inner>
                    <MenuContainer>
                        <MenuHeader
                            closeMenu={this.closeMenu}
                            goBackMenu={this.goBackMenu}
                            updateBasketCompanyInfo={basket.updateBasketCompanyInfo}
                            showVat={products.showVat}
                            toggleVat={products.toggleVat}
                            isMenuFooterShown={isMenuFooterShown}
                            stateHistory={stateHistory}
                            menuItems={menuItems}
                            menuInfoUrl={menuInfoUrl}
                            menuInfoText={menuInfoText}
                            isShown={isShown}
                            showCountrySelector={showCountrySelector}
                            showCountries={showCountries}
                            toggleCountrySelector={this.toggleCountrySelector}
                        />
                        <div>
                            {menuItems &&
                                menuItems.map((item, index) => (
                                    <MenuItems
                                        item={item}
                                        key={index}
                                        id={index}
                                        closeMenu={this.closeMenu}
                                        isActive={activeItem === index}
                                        itemClick={this.itemClick}
                                        isShown={isShown}
                                    />
                                ))}
                        </div>
                        {isMenuFooterShown && (
                            <MenuFooter
                                closeMenu={this.closeMenu}
                                contactPageUrl={contactPageUrl}
                                contactPageText={contactPageText}
                                returnPageUrl={returnPageUrl}
                                returnPageText={returnPageText}
                            />
                        )}
                    </MenuContainer>
                </Inner>
                <div className="menuBg" onClick={this.closeMenu} />
            </MenuWrapper>
        );
    }
}

export default injectModels(['products', 'basket', 'application'])(Menu);
